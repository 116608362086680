import React, { useState, useEffect, useRef } from 'react';
import { MessageList, Message, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './chat-app.css';

const API_URL_MAP = {
  'prod-us': 'https://prod-us.propertyvista-prod.link',
  'uat': 'https://uat-api.propertyvista.link',
  'staging': 'https://staging-api.propertyvista.link',
  'dev': 'https://dev-api.propertyvista.link',
  'default': 'https://x-api.propertyvista.link',
};

const getBaseUrl = () => {
  const hostname = window.location.hostname;
  const environment = Object.keys(API_URL_MAP).find(env => hostname.includes(env));
  return API_URL_MAP[environment] || API_URL_MAP.default;
};

const ChatApp = ({namespace, buildingId}) => {
  console.log(`namespace: ${namespace}, buildingId: ${buildingId}`);
  const baseUrl = getBaseUrl();
  
  const [context, setContext] = useState({});
  const [userInfo, setUserInfo] = useState(null);
  const initializationRef = useRef(false);
  const [containerSize, setContainerSize] = useState({ width: '120px', height: '120px' });
  const [isTransitioning, setIsTransitioning] = useState(false);

  const initializeChat = async (userData) => {
    if (initializationRef.current) return;
    initializationRef.current = true;

    setIsTyping(true); 

    try {
      console.log(`namespace: ${namespace}, buildingId: ${buildingId}, userData: ${JSON.stringify(userData)}`);

      const url = `${baseUrl}/incoming-webchat-init?pmcCode=${encodeURIComponent(namespace)}&buildingId=${encodeURIComponent(buildingId)}&firstName=${encodeURIComponent(userData.firstName)}&lastName=${encodeURIComponent(userData.lastName)}&phone=${encodeURIComponent(userData.phone)}&email=${encodeURIComponent(userData.email)}`;
      console.log(`url: ${url}`);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pmcCode: namespace,
          buildingId: buildingId,
          firstName: userData.firstName,
          lastName: userData.lastName,
          phone: userData.phone,
          email: userData.email,
        }),
      });
      const data = await response.json();
      setContext({ ...data, pmcCode: 'staging' });

      // Update welcome message with property name
      setMessages([{
        message: `${userData.firstName}, welcome to ${data.propertyName}! I'm Vivian, how can I help you?`,
        direction: 'incoming',
        sender: 'Vivian',
      }]);
    } catch (error) {
      console.error('Error initializing chat:', error);
      // Optionally, add an error message to the chat
    } finally {
      setIsTyping(false); 
    }
  };

  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: 'outgoing',
      sender: 'user',
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setIsTyping(true);
    try {
      const response = await fetch(`${baseUrl}/incoming-webchat?pmc_code=${context.pmcCode}&pmc_salesforce_id=${context.pmcSalesforceId}&property_salesforce_id=${context.propertySalesforceId}&inquiry_id=${context.inquiryId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
      });
      console.log(response);

      if (!response.ok) {
        throw new Error('Server response was not ok');
      }

      const data = await response.json();
      
      const replyMessage = {
        message: data.message,
        direction: 'incoming',
        sender: 'Vivian',
      };

      setMessages((prevMessages) => [...prevMessages, replyMessage]);
    } catch (error) {
      console.error('Error:', error);
      // Optionally, add an error message to the chat
    } finally {
      setIsTyping(false);
    }
  };

  const toggleChat = () => {
    setIsTransitioning(true);
    setIsOpen(!isOpen);
    if (isOpen) {
      setContainerSize({ width: '44px', height: '44px' });
    } else {
      setContainerSize({ width: '100%', height: '100%' });
    }
    // Allow time for the transition to complete
    setTimeout(() => setIsTransitioning(false), 300);
  };

  useEffect(() => {
    // Set initial size
    setContainerSize({ width: '44px', height: '44px' });
  }, []);

  const CustomInputArea = ({ onSend, placeholder }) => {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);
    
    const handleSend = () => {
      if (inputValue.trim()) {
        onSend(inputValue);
        setInputValue('');
      }
    };

    useEffect(() => {
      // Focus the input field when the component mounts and after each render
      inputRef.current.focus();
    });

    return (
      <div style={{ display: 'flex', alignItems: 'center', padding: '10px', borderTop: '1px solid #e0e0e0' }}>
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          // placeholder={placeholder}
          style={{ 
            flex: 1, 
            marginRight: '10px', 
            padding: '8px', 
            border: '1px solid #4B77D1',
            borderRadius: '4px',
            outline: 'none',
            backgroundColor: 'rgba(75, 119, 209, 0.2)'
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSend();
            }
          }}
        />
        <div 
          className="send-icon"
          onClick={handleSend}
          style={{
            width: '24px',
            height: '24px',
            cursor: 'pointer',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </div>
    );
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const userData = Object.fromEntries(formData.entries());
    setUserInfo(userData);
    // Initialize chat with user data
    initializeChat(userData);
  };

  const UserInfoForm = () => (
    <form onSubmit={handleFormSubmit} style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      paddingRight: '40px',
      backgroundColor: 'white',
      borderRadius: '10px',
      fontWeight: 'normal',
    }}>
      <input
        type="text"
        name="firstName"
        placeholder="First Name"
        required
        style={inputStyle}
      />
      <input
        type="text"
        name="lastName"
        placeholder="Last Name"
        required
        style={inputStyle}
      />
      <input
        type="email"
        name="email"
        placeholder="Email Address"
        required
        style={inputStyle}
      />
      <input
        type="tel"
        name="phone"
        placeholder="Phone Number"
        required
        style={inputStyle}
      />
      <button type="submit" style={{
        padding: '10px',
        backgroundColor: '#4B77D1',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '10px',
      }}>
        Start Chat
      </button>
      <button 
        onClick={toggleChat}
        className="chat-icon-button"
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          padding: 0,
          width: '24px',
          height: '24px',
          zIndex: 1000,
          opacity: isTransitioning ? 0 : 1,
          transition: 'opacity 0.1s ease-in',
        }}
      >
        <div 
          className="minimize-icon"
          style={{
            width: '100%',
            height: '100%',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </button>
    </form>
  );

  const inputStyle = {
    marginBottom: '10px',
    padding: '8px',
    border: '1px solid #4B77D1',
    borderRadius: '4px',
    outline: 'none',
    backgroundColor: 'rgba(75, 119, 209, 0.2)',
    fontWeight: 'normal',
  };

  return (
    <div className="chat-app-container">
      <div 
        style={{ 
          width: containerSize.width, 
          height: containerSize.height,
          overflow: 'hidden',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          borderRadius: isOpen ? '10px' : '50%',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          transition: 'opacity 0.1s ease-in',
        }}
      >
        {isOpen || isTransitioning ? (
          <>
            {!userInfo ? (
              <UserInfoForm />
            ) : (
              <>
                <div style={{ 
                  flex: 1, 
                  overflow: 'auto', 
                  paddingTop: '40px',
                  paddingBottom: '10px'
                }}>
                  <MessageList>
                    {messages.map((m, i) => 
                    <Message key={i} model={m} />)}
                  </MessageList>
                </div>
                {isTyping && (
                  <div style={{ 
                    padding: '10px', 
                    backgroundColor: '#f4f4f4', 
                    borderTop: '1px solid #e0e0e0'
                  }}>
                    <TypingIndicator content="Vivian is typing" />
                  </div>
                )}
                {!isTyping && (
                  <div style={{
                    padding: '10px',
                    borderTop: '0px'
                  }}>
                    &nbsp;
                  </div>
                )}

                <CustomInputArea 
                  onSend={handleSend}
                  placeholder={`This is Vivian, welcome to ${context.propertyName}, how can I help you today?`}
                />
                <button 
                  onClick={toggleChat}
                  className="chat-icon-button"
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: 0,
                    width: '24px',
                    height: '24px',
                    zIndex: 1000,
                    opacity: isTransitioning ? 0 : 1,
                    transition: 'opacity 0.1s ease-in',
                  }}
                >
                  <div 
                    className="minimize-icon"
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  />
                </button>
              </>
            )}
          </>
        ) : null}
        <button 
          onClick={toggleChat}
          className="chat-icon-button"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: isOpen || isTransitioning ? 0 : 1,
            transition: 'opacity 0.1s ease-in',
          }}
        >
          <div 
            className="chat-icon"
            style={{
              width: '80%',
              height: '80%',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </button>
      </div>
    </div>
  );
};

export default ChatApp;
